body:not(.hasTouchEffect) .scrollbar {
    overflow: auto;
    &::-webkit-scrollbar {
        width: .25rem;
        height: .25rem;
    }
    
    
    &::-webkit-scrollbar-thumb {
        background: var(--accent-5);
        border-radius: 5rem;
    }
    &:hover::-webkit-scrollbar-thumb {
        background-color: var(--accent-4);
    }
}