.bar-loader {
    @extend .position-relative;
    height: 0.25em;
    border-radius: 1em;
    color: var(--primary);
    overflow: hidden;
    &::after, &::before {
        content: '';
        @extend .position-absolute, .h-100, .top-0;
        border-radius: inherit;
        background: currentColor;
    }
    &::before {
        opacity: 0.2;
        @extend .w-100;
    }
    &::after {
        animation: linear 1s BarLoader infinite;
        width: 25%;
    }
}

@keyframes BarLoader {
    0% {
        left: -25%;
    }
    100% {
        left: 100%;
    }
}


.chat-loader {
    position: relative;
    animation: infinite 0.3s FLIPAnimation;
    &, &::after, &::before {
        min-width: 1em;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        background-color: currentColor;
    }
    &::after, &::before {
        content: '';
        position: absolute;
    }
    &::after {
        left: -2em;
    }
    &::before {
        right: -2em;
    }
}

.Flash-Loader {
    display: flex;
    width: 0.5em;
    height: 0.5em;
    min-width: 0.5em;
    min-height: 0.5em;
    border-radius: 50%;
    background-color: #717272;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
    left: 1em;
  }
  
  @keyframes flash {
    0% {
      background-color: #70737333;
      box-shadow: 1em 0 #70737333, -1em 0 #717272;
    }
    50% {
      background-color: #717272;
      box-shadow: 1em 0 #70737333, -1em 0 #70737333;
    }
    100% {
      background-color: #70737333;
      box-shadow: 1em 0 #717272, -1em 0 #70737333;
    }
  }
        

@keyframes FLIPAnimation {
    0% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(-1);
    }
}