.root {
    >div:not(:last-child) {
        border-bottom: 0.0625rem solid var(--accent-6);
    }
    @media (min-width: 1300px) {
        >div:not(:last-child) {
            border-top: none;
            border-right: 0.0625rem solid var(--accent-6);
        }
    }
}