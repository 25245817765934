.headRow {
    >th {
        &:not(:first-child) {
            cursor: pointer;
            &::after {
                content: '↑';
                font-size: 1.25em;
                margin-left: 0.5em;
                color: var(--primary);
                opacity: 0;
            }
            &:hover, &[class *= "active"] {
                &::after {
                    opacity: 1;
                }
            }
    
            &[class *= "reverse"]::after {
                transform: rotate(180deg);
                display: inline-block;
            }
        }
    }
}