#popup-wrapper{
    // position: relative;
    max-height: 100vh;
    overflow: hidden;
}
.MuiPopover-root {
    z-index: 10002 !important;
}
body{
    position: relative;
}
.model-title{
    position: absolute;
    top: 0;
    left: 2rem;
    right: 4rem;
    top: 2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
}

.model-footer{
    display: flex;
    justify-content: flex-end;
    border-top: solid 0.0625rem var(--accent-5);
    gap: inherit;
    padding-top: 2rem;
    margin-top: auto;
}

@media (max-width: 992px) {
    .model-footer{
        padding-top: 1.5rem;
    }
    .model-title{
        left: 1.5rem;
        top: 1.5rem;
    }
}

@media (max-width: 767px) {
    .model-title{
        left: 1rem;
        top: 1rem;
    }
    .model-footer{
        padding-top: 1rem;
    }
}