$height: 3rem;

.main {
    background: url('../../assets/leaderboard-bg.jpg') center center no-repeat;
    background-size: cover;
    padding: 1.25rem;
}

.footer {
    height: 3rem;
}