.card {
    border: 1px solid var(--accent-6);
    @extend .bg-surface-1, .overflow-hidden;
    @media (min-width: 991px) {
        border-radius: .5rem;
    }
}

.shadow-5 {
    box-shadow: 0px 0px .0625rem 0px rgba(0, 0, 0, 0.25), 0px .625rem 1.25rem 0px rgba(0, 0, 0, 0.25), 0px 0px 3.125rem 0px rgba(0, 0, 0, 0.50);
}

.hightlight {
    box-shadow: inset 0 0 0.375rem 0 rgba(0, 0, 0, 0.15);
    @extend .p-3;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 1.25rem;
    background-color: rgba(239, 240, 241, 1);
}