.scoreWrapper{
    min-height: 300px;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 1px;
        background: var(--accent-6);
    }
}