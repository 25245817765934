.filter-panel-wrapper {
    position: sticky;
    top: 5.5rem;
    width: 15.01rem;
}

.filter-panel {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

@media (max-width: 1100px) {
    .filter-panel-layout {
        grid-template-columns: 13rem 1fr;
    }
}

@media (max-width: 1050px) {
    .filter-panel-layout {
        grid-template-columns: 12.5rem 1fr;
    }
}

$duration: 0.2s;

@media (max-width: 992px) {
    .filter-panel-wrapper {
        position: static;
        width: initial;
    }

    .filter-container {
        position: fixed;
        z-index: 3;
        top: 4rem;
        width: 100%;
        bottom: 0;
        left: 0;
        display: flex;
        overflow: initial hidden;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: -100%;
            transition: $duration linear all;
            background: rgba(var(--text-rgb), 0.5);
        }

        &:not(.active) {
            left: -100%;
            transition-delay: $duration;

            &::before {
                visibility: hidden;
                transition-delay: $duration;
                opacity: 0;
            }

            >.btn {
                opacity: 0;
                transform: scale(0);
            }
        }

        &,
        >.btn {
            transition: linear $duration all;
        }

        &.active {
            >.btn {
                transition-delay: $duration;
                position: relative;
            }
        }
    }

    .filter-panel-layout {
        grid-template-columns: 1fr;
        padding: 1rem;
        gap: 1rem;
    }

    .filter-panel {
        background: #fff;
        padding: 1rem;
        position: relative;
        overflow: hidden auto;
        max-width: 20rem;
        min-width: 18rem;
    }
}

@media (max-width: 767px) {
    .filter-container {
        top: 3.25rem;
    }
}