%btn-hover {
    &:not(:disabled) {
        position: relative;
        &:active {
            box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
            background-color: var(--primary-darken-1);
        }
        &::before {
            content: '';
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            background: var(--surface-1);
            border-radius: inherit;
            opacity: 0;
            z-index: 1;
        }
        &:hover::before {
            opacity: 0.2;
        }
    }
    &:disabled {
        background-color: var(--text) !important;
    }
}

body:not(.hasTouchEffect) {
    .btn-text:not(:disabled):hover {
        text-decoration: underline;
    }

    .btn-text.btn-underline:not(:active):not(.active):hover {
        opacity: 0.85;
    }

    .btn-primary {
        &:not(:active):hover {
            &::before {
                opacity: 0.2;
            }

            box-shadow: 0px 11px 23px 0px rgba(0, 0, 0, 0.149);
        }
    }

    .btn-border:not(:disabled) {
        &[class *="text-"]:hover {
            color: var(--primary) !important;

            >span {
                color: inherit !important;
            }
        }

        &:not([class *="text-"]) {
            &:hover {
                background-color: var(--primary-lighten-5);
            }

            &:active {
                background-color: var(--primary-lighten-4);
            }
        }
    }

    .btn-grediant:not(:disabled):hover {
        background: linear-gradient(180deg,
                rgba(255, 255, 255, 0.33) 0%,
                rgba(255, 255, 255, 0) 100%),
            linear-gradient(90deg, var(--primary) 0.02%, var(--accent-3) 100.04%);
        box-shadow: 0px 17px 46px rgba(0, 0, 0, 0.25);
    }

    .btn-landing-page,
    .btn-landing-page-lg {
        &:not(:disabled):hover {
            color: var(--text);
        }
    }

    .btn-grediant-normal:not(:disabled):hover:before {
        content: '';
    }

    .btn-icon,
    .MuiIconButton-root {
        &:hover {
            &::after {
                opacity: 0.2;
            }
        }
    }

    .btn-rounded:not(:disabled):hover {
        color: var(--primary);
    }

}

.btn-hover {
    @extend %btn-hover;
}