.root{
    position: relative;
    min-height: 270px;
    &::before{
        content: '';
        display: block;
        padding-top: 45%;
    }
    [class *= "recharts-default-legend"]{
        margin-top: 1rem !important;
    }
    &[class *= "yAxis"]{
        margin-left: -1.5rem;
    }
}
.main{
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
.xAxisLabel{
    text-align: center;
    margin: 0;
}
@media (max-width: 767px) {
    .root.yAxis{
        margin-left: -1.8rem;
    }
}