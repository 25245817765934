.date__range__wrapper{
    position: relative;
    .rdrDays{
        display: block;
    }
    @media (max-width: 610px) {
        .rdrDefinedRangesWrapper{
            display: none;
        }
    }
    @media (max-width: 480px) {
        .rdrDateRangePickerWrapper{
            width: 100%;
        }
        .rdrMonth{
            width: 100%;
        }
        .rdrCalendarWrapper{
            width: 100%;
        }
    }
    @media (max-width: 358px) {
        .rdrCalendarWrapper{
            font-size: 11.5px;
        }
    }
    @media (max-width: 330px) {
        .rdrCalendarWrapper{
            font-size: 11px;
        }
    }
}

.date__range__model{
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
}