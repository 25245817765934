.font-space-mono {
    font-family: 'Space Mono', monospace;
}

.dangerouslySetInnerHTML {
    display: grid;
    gap: 1rem;

    &, * {
        font-size: inherit !important;
        font-family: inherit !important;
        margin: 0 !important;
        text-indent: initial !important;
        text-overflow: ellipsis;
        line-height: initial !important;
        color: inherit !important;
        background-color: inherit !important;
    }
}

.fs-normal {
    font-size: 1rem;
}

h1,
.h1 {
    font-size: 5rem;
    font-weight: 600;
}

h2,
.h2 {
    font-size: 4rem;
    font-weight: 600;
}

h3,
.h3 {
    font-size: 3rem;
    font-weight: 600;
}
.h3-h4 {
    font-size: 2.5rem;
}

h4,
.h4 {
    font-size: 2rem;
    font-weight: 600;
}

h5,
.h5 {
    font-size: 1.5rem;
    font-weight: 600;
}

h6,
.h6 {
    font-size: 1.25rem;
    font-weight: 600;
}

.h7 {
    font-size: 1.75rem;
    font-weight: 600;
}

.fs-mediam {
    font-size: 0.8889rem;
}

small,
.small,
.overline {
    font-size: 0.875rem !important;
}

.smaller {
    font-size: 0.75rem !important;
}

.text-xs {
    font-size: .625rem !important;
}

b {
    font-weight: 600;
}

.subtitle {
    font-size: 1.125rem;
}

.overline, .f-title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.fs-inherit {
    font-size: inherit;
}

.letter-spacing-3 {
    letter-spacing: 0.37em;
}

.overline-title {
    @extend .fs-mediam;
    font-size: 0.9375rem;
    @extend .letter-spacing-3;
    @extend .text-uppercase;
}

@media (max-width: 1700px) {
    .fs-normal-xl {
        font-size: 1rem !important;
    }

    .h2-xl {
        font-size: 4rem !important;
    }

    .h3-xl {
        font-size: 3rem !important;
    }

    .h4-xl {
        font-size: 2rem !important;
    }

    .h5-xl {
        font-size: 1.5rem !important;
    }

    .h6-xl {
        font-size: 1.25rem !important;
    }

    .fs-mediam-xl {
        font-size: 0.8889rem !important;
    }

    .small-xl,
    .overline-xl {
        font-size: 0.875rem !important;
    }

    .subtitle-xl {
        font-size: 1.125rem !important;
    }

    .smaller-xl {
        font-size: 0.75rem !important;
    }
}

@media (max-width: 1300px) {
    .fs-normal-lg {
        font-size: 1rem !important;
    }

    .h2-lg {
        font-size: 4rem !important;
    }

    .h3-lg {
        font-size: 3rem !important;
    }

    .h4-lg {
        font-size: 2rem !important;
    }

    .h5-lg {
        font-size: 1.5rem !important;
    }

    .h6-lg {
        font-size: 1.25rem !important;
    }

    .fs-mediam-lg {
        font-size: 0.8889rem !important;
    }

    .small-lg,
    .overline-lg {
        font-size: 0.875rem !important;
    }

    .subtitle-lg {
        font-size: 1.125rem !important;
    }

    .smaller-lg {
        font-size: 0.75rem !important;
    }
}

@media (max-width: 991px) {
    .fs-normal-sm {
        font-size: 1rem !important;
    }

    .h2-sm {
        font-size: 4rem !important;
    }

    .h3-sm {
        font-size: 3rem !important;
    }

    .h4-sm {
        font-size: 2rem !important;
    }

    .h5-sm {
        font-size: 1.5rem !important;
    }

    .h6-sm {
        font-size: 1.25rem !important;
    }

    .fs-mediam-sm {
        font-size: 0.8889rem !important;
    }

    .small-sm,
    .overline-sm {
        font-size: 0.875rem !important;
    }

    .subtitle-sm {
        font-size: 1.125rem !important;
    }

    .smaller-sm {
        font-size: 0.75rem !important;
    }
}

@media (max-width: 500px) {
    .fs-mediam-xs {
        font-size: 0.8889rem !important;
    }
    .xs-small {
        font-size: .625rem;
    }   
}