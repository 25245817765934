@media (min-width: 991px) {
    .Nav-DropDown{
        opacity: 0;
        visibility: hidden;
        transition: linear 0.2s all;
        min-width: 12.1875rem;
        text-wrap: nowrap;
        color: var(--text);
        position: fixed;
        border-radius: 0.5rem;
        box-shadow: 0 .625rem 1.25rem rgba(0, 0, 0, 0.25), 0 0 .0625rem rgba(0, 0, 0, 0.25);
        background: var(--surface-1);
        margin-top: 0.5rem;
        z-index: 9;
        &.active, &:hover, &.normal{
            opacity: 1;
            visibility: visible;
        }
        >div{
            position: relative;
            z-index: 1;
            background: inherit;
        }
    }
}