.AlgoItem {
    @extend .cursor-pointer, .text-accent-4, .elevation-1, .position-relative, .elevation-3-hover, .flex-1, .d-flex, .flex-column, .border-radius-8, .fs-normal;
    overflow: hidden;
    &, * {
        transition: linear 0.2s all;
    }
    h5 {
        @extend .d-flex, .text-normal, .justify-content-between, .align-items-center;
    }
    &:not(:hover) {
        .AlgoItem-Main {
            margin-top: 0;
        }
        h5 svg {
            opacity: 0;
            visibility: hidden;
        }
        .AlgoItem-Blog {
            -webkit-line-clamp: 3 !important;
            height: 3.75em !important;
        }
    }
}

.AlgoItem-Blog {
    height: 5em;
    @extend .m-0, .d-webkit-box, .lines-4, .dangerouslySetInnerHTML;
    line-height: 1.25 !important;
}

.AlgoItem-Main {
    padding: 1em;
    gap: 0.5em;
    margin-top: -1.25em;
    @extend .position-relative, .d-flex, .flex-column, .flex-1, .bg-surface-1, .z-index-1;
    h5 {
        font-size: 1.25em;
        margin: 0;
    }
}


.AlgoItem-Badge {
    top: -2.25em;
    right: 0.5em;
    border-radius: 2em;
    padding: 0.25em 0.5em;
    @extend .bg-surface-1, .text-uppercase, .position-absolute, .text-normal, .fw-semibold;
}

.AlgoItem-btn {
    top: 0.25em;
    left: 0.25em;
    @extend .text-surface-1, .fs-inherit, .btn, .btn-icon, .z-index-1, .position-absolute;
}