.root {
    @media (min-width: 768px) {
        max-width: 26rem;
    }
}

.main {
    text-overflow: ellipsis;;
    overflow: hidden;
    >label {
        display: flex;
        padding: 0;
        border-top: 1px solid var(--accent-6);
        >span {
            flex:1;
            padding: 0.5rem 1rem;
        }
        span[ticker]{
            &::before {
                content: attr(ticker);
                width: 5.5rem;
                display: inline-block;
                color: var(--text);
            }
        }
        &:hover {
            &, & + label {
                border-color: var(--primary-lighten-2);
            }
            span[ticker]::before {
                color: inherit;
            }
        }
    }
}