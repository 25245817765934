.root {
    min-height: calc(var(--vpe-height) - 2.1rem);
}

.active {
    @media (min-width: 991px) {
        [class *= "ToolPage"] {
            max-height: calc(var(--vpe-height) - 2.1rem) !important;
        }
    }
}