.yAxis {
    width: 1em;
}

.chartWrapper {
    height: 500px;
}

@media (max-width: 700px) {
    .chartWrapper {
        height: 300px;
    }
}