:root {
  --primary: #025ed7;
  --primary-lighten-1: #357edf;
  --primary-lighten-2: #679ee7;
  --primary-lighten-3: #9abfef;
  --primary-lighten-4: #ccdff7;
  --primary-lighten-5: #e9f1fc;
  --primary-darken-1: #024bac;
  --primary-darken-2: #013881;
  --primary-darken-3: #012656;
  --primary-darken-4: #151b26;

  --accent-1: #ffc803;
  --accent-2: #dc0000;
  --accent-3: #0ac171;
  --accent-4: #717272;
  --accent-5: #b8b8b8;
  --accent-6: #e7e7e7;
  --accent-7: #BE5BF3;
  --accent-8: #FF8E25;
  --accent-9: #FF9428;
  --accent-10: #48076A;

  --surface-1-rgb: 255, 255, 255;
  --surface-1: rgb(var(--surface-1-rgb));
  --surface-2: #f6f8f9;
  --surface-3: #EFF0F1;
  --text-rgb: 0, 0, 0;
  --text: rgba(var(--text-rgb), 1);
  --app-height: 100vh
}

.text-normal {
  color: var(--text) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-primary-active {

  &:hover,
  &.active,
  &:active {
    color: var(--primary) !important;
  }
}

.text-primary-lighten-1 {
  color: var(--primary-lighten-1) !important;
}

.text-primary-lighten-2 {
  color: var(--primary-lighten-2) !important;
}

.text-primary-lighten-3 {
  color: var(--primary-lighten-3) !important;
}

.text-primary-lighten-4 {
  color: var(--primary-lighten-4) !important;
}


.bg-primary {
  background-color: var(--primary) !important;
}

.bg-primary-lighten-1 {
  background-color: var(--primary-lighten-1) !important;
}

.bg-primary-lighten-2 {
  background-color: var(--primary-lighten-2) !important;
}

.bg-primary-lighten-3 {
  background-color: var(--primary-lighten-3) !important;
}

.bg-primary-lighten-4 {
  background-color: var(--primary-lighten-4) !important;
}

.bg-primary-lighten-5 {
  background-color: var(--primary-lighten-5) !important;
}





.text-primary-darken-1 {
  color: var(--primary-darken-1) !important;
}

.text-primary-darken-2 {
  color: var(--primary-darken-2) !important;
}

.text-primary-darken-3 {
  color: var(--primary-darken-3) !important;
}

.text-primary-darken-4 {
  color: var(--primary-darken-4) !important;
}


.bg-primary-darken-1 {
  background-color: var(--primary-darken-1) !important;
}

.bg-primary-darken-2 {
  background-color: var(--primary-darken-2) !important;
}

.bg-primary-darken-3 {
  background-color: var(--primary-darken-3) !important;
}

.bg-primary-darken-4 {
  background-color: var(--primary-darken-4) !important;
}




.text-accent-1 {
  color: var(--accent-1) !important;
}

.text-accent-2 {
  color: var(--accent-2) !important;
}

.text-accent-3 {
  color: var(--accent-3) !important;
}

.text-accent-4 {
  color: var(--accent-4) !important;
}

.text-accent-5 {
  color: var(--accent-5) !important;
}

.text-accent-6 {
  color: var(--accent-6) !important;
}

.text-accent-8 {
  color: var(--accent-8) !important;
}

.bg-accent-1 {
  background-color: var(--accent-1) !important;
}

.bg-accent-2 {
  background-color: var(--accent-2) !important;
}

.bg-accent-3 {
  background-color: var(--accent-3) !important;
}

.bg-accent-4 {
  background-color: var(--accent-4) !important;
}

.bg-accent-5 {
  background-color: var(--accent-5) !important;
}

.bg-accent-6 {
  background-color: var(--accent-6) !important;
}

.bg-accent-8 {
  background-color: var(--accent-8) !important;
}

.bg-accent-9 {
  background-color: var(--accent-9) !important;
}

.bg-normal {
  background-color: var(--text) !important;
}

.text-surface-1 {
  color: var(--surface-1) !important;
}

.text-surface-2 {
  color: var(--surface-2) !important;
}

.text-surface-3 {
  color: var(--surface-3) !important;
}

.bg-surface-1 {
  background-color: var(--surface-1) !important;
}

.bg-surface-2 {
  background-color: var(--surface-2) !important;
}

.bg-surface-3 {
  background-color: var(--surface-3) !important;
}

.bg-inherit {
  background-color: inherit !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-accent-7 {
  background-color: var(--accent-7) !important;
}

.text-accent-7 {
  color: var(--accent-7) !important;
}

.bg-none {
  background-color: transparent !important;
  box-shadow: initial !important;
}

.bg-accent-10 {
  background-color: var(--accent-10) !important;
}


.bg-accent-1-gradient {
  color: var(--text) !important;
  background: linear-gradient(180deg, var(--accent-1) 0%, var(--accent-9) 100%) !important;
}

.bg-accent-7-gradient {
  background: linear-gradient(180deg, var(--accent-7) 0%, var(--accent-10) 100%) !important;
}

.bg-primary-gradient {
  background: linear-gradient(0deg, var(--primary-lighten-1) 0%, var(--primary-darken-2) 100%);
}


.bg-surface-1-gradient {
  background: linear-gradient(90deg, rgba(var(--surface-1-rgb), 0) 0%, rgba(var(--surface-1-rgb), 0.2) 51.73%, rgba(var(--surface-1-rgb), 0) 100%);
}

.bg-gradient-5 {
  background: linear-gradient(135deg, #BE5BF3 0%, #9020CC 100%), radial-gradient(174.61% 159.15% at 100.00% 0.00%, #7203FF 0%, #F800DF 100%) !important;
}

.bg-gradient-6 {
  background: linear-gradient(180deg, #BE5BF3 0%, #933CC1 100%) !important;
}

.bg-stripe {
  background-color: #635bff !important;
}

[class *= "btn-border"][class *= "gradient"] {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
}

.bg-primary-gradient-2 {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), var(--primary) !important;;
}