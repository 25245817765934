.main {
    gap: .75rem;
    ul {
        border-radius: .25rem;
        overflow: hidden;
        border: .0625rem solid var(--accent-6);
        padding: 0;
        margin: 0;
        list-style-type: none;
        position: relative;
        &::before {
            content: 'Parameter';
            padding: .5rem .75rem;
            display: block;
            font-weight: 600;
            background-color: var(--accent-6);
        }
        &::after {
            content: 'Values';
            position: absolute;
            top: 0.5rem;
            right: 0.75rem;
            font-weight: 600;
        }
    }
    li {
        padding: .5rem .75rem;
        display: flex;
        justify-content: space-between;
        &:not(:last-child) {
            border-bottom: inherit;
        }
    }
}