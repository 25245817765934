.root {
  [class*="MuiSlider-thumb"] {
    width: 1rem;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.45rem;
    color: var(--surface-1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
}
