.flex-initial {
  flex: initial;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}

.order-1 {
  order: -1;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-middle {
  @extend .flex-center;
  justify-content: center;
}

.flex-between {
  @extend .flex-center;
  justify-content: space-between;
}

@media (min-width: 576px) {
  .flex-sm-initial {
    flex: initial;
  }
  .flex-sm-1 {
    flex: 1;
  }
  .flex-sm-2 {
    flex: 2;
  }
  .flex-sm-3 {
    flex: 3;
  }
  .flex-sm-4 {
    flex: 4;
  }
}

@media (min-width: 768px) {
  .flex-md-initial {
    flex: initial;
  }
  .flex-md-1 {
    flex: 1;
  }
  .flex-md-2 {
    flex: 2;
  }
  .flex-md-3 {
    flex: 3;
  }
  .flex-md-4 {
    flex: 4;
  }
}

@media (min-width: 992px) {
  .flex-lg-initial {
    flex: initial;
  }
  .flex-lg-1 {
    flex: 1;
  }
  .flex-lg-2 {
    flex: 2;
  }
  .flex-lg-3 {
    flex: 3;
  }
  .flex-lg-4 {
    flex: 4;
  }
}

@media (min-width: 1300px) {
  .flex-xl-initial {
    flex: initial;
  }
  .flex-xl-1 {
    flex: 1;
  }
  .flex-xl-2 {
    flex: 2;
  }
  .flex-xl-3 {
    flex: 3;
  }
  .flex-xl-4 {
    flex: 4;
  }
}

@media (min-width: 1600px) {
  .flex-xxl-initial {
    flex: initial;
  }
  .flex-xxl-1 {
    flex: 1;
  }
  .flex-xxl-2 {
    flex: 2;
  }
  .flex-xxl-3 {
    flex: 3;
  }
  .flex-xxl-4 {
    flex: 4;
  }
}
