.my-page {
    margin-top: 7.8rem !important;
    margin-bottom: 7.8rem !important;
}
.py-page {
    padding-top: 7.8rem !important;
    padding-bottom: 7.8rem !important;
}


.my-sm-page {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
}
.py-sm-page {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
}

.gap-sm-page {
    gap: 6.25rem !important;
}

@media (max-width: 991px) {
    .my-page {
        margin-top: 5rem !important;
        margin-bottom: 5rem !important;
    }
    .py-page {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }


    .my-sm-page {
        margin-top: 4rem !important;
        margin-bottom: 4rem !important;
    }
    .py-sm-page {
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }
}