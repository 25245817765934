.root {
    li:not(.menuButon) {
        flex: 1;
    }
}

.menuButon {
    width: 5rem;
    border-left: 1px solid rgba($color: #fff, $alpha: 0.2);
}

.active .menuButon svg {
    transform: rotate(45deg) scale(1.1);
}

.root:not(.active) .menuButon svg {
    width: 1.5em;
}

@media (max-width: 992px) and (min-width: 767px) {
    .root {
        max-width: 30rem;
        margin-left: auto;
    }
}


@media (max-width: 992px) {
    .root:not(.active) + div {
        display: none;
    }
}