.root {
    background: linear-gradient(90deg, #357EDF 0.02%, #013881 100.04%);
}

.main {
    max-width: 62rem;
}

@media (min-width: 767px) {
    .btn {
        min-width: 16.875rem;
    }
    .root {
        border-radius: .5rem;
    }
}