.border-radius-20{
    border-radius: 1.25rem !important;
}
.border-radius-8{
    border-radius: 0.5rem;
}
.border-radius-40 {
    border-radius: 2.5rem;
}
.border-radius-50{
    border-radius: 50%;
}
.border-radius-14{
    border-radius: 0.875rem;
}
.border-radius-10{
    border-radius: 0.625rem;
}
.border-radius-inherit{
    border-radius: inherit;
}
.border-radius-4 {
    border-radius: .25rem;
}
@media (max-width: 1200px) {
    [class *= "border-radius-1"]{
        border-radius: 1rem;
    }
}
@media (max-width: 992px) {
    [class *= "border-radius-1"]{
        border-radius: 0.75rem;
    }
}
@media (max-width: 767px) {
    [class *= "border-radius-1"]{
        border-radius: 0.5rem;
    }
}