$header-height: 4rem;
$height-height-sm: 3.75rem;
$bg: rgba(var(--surface-1-rgb), 0.1);


%bg {
    background-color: rgba(var(--surface-1-rgb), 0.1);
}

.Header-Link {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    height: $header-height;
    padding: 0 1.5rem 0 1.2rem;
    background-color: transparent;
    color: inherit;
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
    font-size: inherit;
    text-decoration: none !important;
    svg {
        width: 1.5rem;
        max-height: 1.2rem;
        stroke: currentColor;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &::before {
        top: 0;
    }
}

body:not(.hasTouchEffect) .Header-Link:not(.active):hover {
    svg {
        fill: $bg;
    }
    &::before {
        @extend %bg;
    }
}

.Header-Link.active,
li.active .Header-Link {
    cursor: default;

    &::before {
        @extend %bg;
    }

    svg {
        fill: currentColor;
        stroke: var(--primary-darken-4);
    }

    &::after {
        height: 0.3125rem;
        background: var(--primary);
    }
}

.no-fill-icon svg{
    fill: initial !important;
}

@media (min-width: 991px) {
    .Header-Icon-Link {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: .5rem;
        overflow: hidden;
        padding: 0;

        &::after {
            content: initial;
        }

        svg {
            margin: auto;
        }
    }
}

@media (max-width: 992px) {
    .Header-Link {
        flex-direction: column;
        gap: 0.25rem;
        padding: 0.7rem 0;
        justify-content: center;

        &::after {
            height: 0.25rem;
        }
    }
}

@media (max-width: 575px) {
    .Header-Link {
        font-size: .875rem;
    }
}