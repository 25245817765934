.__custom-input-field {
  border-radius: 0.5rem;

  &.w-normal {
    min-width: 14.25rem;
  }

  input {
    border-radius: 0.5rem;

    &::-ms-reveal,
    &::-ms-clear {
      display: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .MuiSelect-icon {
    top: 1rem;
    right: 0.8rem;
  }

  * {
    font-family: "Inter", sans-serif !important;
  }

  * div {
    background: transparent !important;
  }

  .MuiOutlinedInput-multiline {
    padding: 1rem;

    .MuiInputBase-input {
      padding: 0;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: 0.125rem solid var(--accent-6) !important;
    border-radius: .5rem;
  }
  .Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #ef5350 !important;
  }

  .MuiInputBase-input {
    padding: 1rem;
    color: var(--text);
  }

  .MuiInputLabel-outlined {
    transform: translate(1rem, 1.09375rem) scale(1);
  }

  .MuiInputLabel-shrink {
    transform: translate(1rem, -6px) scale(0.75) !important;
    font-weight: 600 !important;
  }

  legend {
    margin-left: 2px;
  }
}

.__custom-input-field .MuiInputBase-root {
  width: 100%;
}

.__custom-input-field .MuiInputBase-root:hover:not(.Mui-error):not(.Mui-disabled):not(.Mui-focused) {
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--accent-5) !important;
  }
}

.__custom-input-field .MuiInputBase-root.Mui-focused {
  &:not(.Mui-error) {
    color: var(--primary) !important;

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--primary) !important;
    }
  }
}

.__errorBlogContainer .MuiFormHelperText-root {
  display: none;
}

.__custom-input-field .MuiFormHelperText-root {
  text-align: right;
  line-height: 1.25;
}

.__custom-input-field {

  .Mui-error,
  &.invalid-field {
    .MuiFormHelperText-root {
      color: var(--accent-2) !important;
      display: block;
    }
  }
}

.MuiListItem-root {
  padding: 0.843rem 1rem !important;
  max-width: 100%;
  outline: none;
  cursor: pointer;
  font-family: "Inter", sans-serif !important;

  &:hover {
    background: var(--surface-2) !important;
  }

  &:active,
  &.Mui-selected {
    background: var(--primary-lighten-5) !important;
    color: var(--primary);
  }
}

.MuiList-padding {
  padding: 0 !important;
}

.__custom-input-field {
  .Mui-disabled {
    color: #8888 !important;
    cursor: not-allowed;

    .MuiOutlinedInput-notchedOutline {
      border-color: #e5e5e5 !important;
    }
  }
}

.__custom-input-field .MuiInputAdornment-positionStart {
  margin-right: 0.125rem;

  &~.MuiInputBase-input {
    padding-left: 0 !important;
  }
}


.__custom-input-field .MuiOutlinedInput-inputAdornedEnd {
  ~[class *="btn-icon"] {
    margin: 0 -0.5rem 0 0.2rem;
  }
}