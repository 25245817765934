.root{
    min-height: 13.25rem;
    border-radius: 0.75rem;
    >iframe{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }
    @media (max-width: 767px) {
        border-radius: 0.5rem;
    }
}