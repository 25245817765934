.table-wrapper {

  // padding: 0.875rem 0;
  &:not(.fs) {
    @extend .smaller;
  }

  text-transform: uppercase;
  white-space: nowrap;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;

  >div {
    overflow: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: inherit;
  }

  thead {
    th {
      position: sticky;
      top: 0;
      background: var(--surface-1);
    }
  }

  td,
  th {
    text-align: left;
    padding: 0.5rem 0.8125rem;

    &:empty {
      &::after {
        content: '';
        display: inline-block;
        min-height: 2rem;
      }
    }

    &:not(.long) {
      max-width: 35%;
    }

    &.long {
      min-width: 20rem;
    }

    &:first-child {
      padding-left: 1.625rem;
    }

    &:last-child {
      padding-right: 1.625rem;
    }

    >div:not(.position-absolute):not(.details) {
      overflow: hidden;
      height: 2rem;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      max-width: 24.25rem;
    }

    >div.details {
      white-space: normal;
    }
  }

  th {
    background: inherit;
  }

  .description {
    min-height: 2rem;
    display: flex;
    align-items: center;

    >span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      box-orient: vertical;
      -webkit-box-orient: vertical;
      white-space: normal;
      height: initial;
      min-width: 6.5rem;
      overflow: hidden;
    }
    &.lines-4 {
      >span {
        -webkit-line-clamp: 4;
        line-clamp: 4;
        min-width: initial !important;
      }
    }
    &.lines-10 {
      >span {
        -webkit-line-clamp: 10;
        line-clamp: 10;
      }
    }
  }

  tbody tr {
    &:nth-child(odd) {
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)),
        #c2c9d1;
    }

    &:nth-child(even) {
      background: var(--surface-1);
    }

    >table th {
      background-color: var(--text);
    }
  }

  .primary-table tbody tr:nth-child(odd) {
    background: #f5f9fd;
  }

  button {
    font-size: inherit;
    text-transform: inherit;
  }
}

.table-grey {
  thead {
    opacity: 1;
  }

  th {
    background: var(--accent-5);
  }

  tbody tr {
    &:nth-child(odd) {
      background: transparent;
    }

    &:nth-child(even) {
      background: var(--accent-6);
    }
  }

  td,
  th {
    >div:not(.details) {
      min-height: 2.5rem;
    }
  }
}

.table-rounded {

  th,
  td {
    &:first-child {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    &:last-child {
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }
}


.table-border table {
  th {
    background-color: #eff0f1;
  }

  tr {
    background-color: transparent !important;

    th,
    td {
      padding: .5rem .75rem !important;

      &:not(:last-child) {
        padding-right: 0 !important;
      }
    }
  }

  td {
    border-bottom: solid 0.0625rem var(--accent-6);
  }
}

@media (max-width: 800px) {
  .table-responsive {

    table,
    tr,
    tbody {
      display: block;
    }

    tr {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--accent-6);
      border-radius: 0.4rem;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    td {
      display: flex;
      max-width: initial;
      padding: 1rem !important;

      &:not(:last-child) {
        border-bottom: 1px solid var(--accent-6);
      }

      >div {
        height: initial;
      }

      &::before {
        content: attr(title);
        font-weight: 500;
        margin-right: 4rem;
        margin-right: auto;
      }
    }

    thead {
      display: none;
    }

    >div {
      overflow: initial;
    }
  }
}