.on-board-process {
    background: rgba(var(--text-rgb), 0.5);
    overflow: auto;

    >div {
        transition: linear 0.3s all;
        position: absolute;

        &:not(.active) {
            opacity: 0;
            visibility: hidden;
        }

        &:not(.model) {
            max-width: calc(100% - 2rem);
        }
    }
}

.leaderboard-active,
.header-links-active {
    +div>header {
        position: static !important;
    }
}

.header-links-active +div #marketplace-link,
.leaderboard-active +div #leaderboard-link{
    z-index: 8;
    &::before {
        background-color: rgba(var(--surface-1-rgb), 0.1);
    }
}
.algoitem-active~div #algo-list>div:first-child {
    z-index: 8;
    padding: 0.5rem;
    background: var(--surface-3);
    transform: scale(1.05);
}