.__Fundamentals-RigthDrawer {
    animation: FundamentalsRightDrawerAnimation 0.3s linear forwards;
}


@keyframes FundamentalsRightDrawerAnimation{
    0% {
        opacity: 0;
        right: -50%;
    }
    100% {
        opacity: 1;
        right: 0;
    }
}