.Tools-Subscription {
    background-color: #9020CC;
    transition: 0.3s all;
    &::after {
        content: '';
        width: 5rem;
        position: absolute;
        height: 15rem;
        background: linear-gradient(to right, var(--accent-10),var(--accent-7), var(--accent-10));
        transform: rotate(135deg);
        opacity: 0.3;
        animation: infinite 2s ToolsSubscription;
        left: -10%;
        transition: linear 0.5s all;
    }
    &:hover{
        background-color: #731AA3;
        &::after {
            left: 110%;
        }
    }
    >span {
        z-index: 1;
        &:last-child {
            position: relative;
            top: 2em;
            opacity: 0;
            transition: inherit;
        }
    }
    &:hover>span:last-child {
        opacity: 1;
        top: 0;
    }
    @media (max-width: 380px) {
        font-weight: 500 !important;
    }
}