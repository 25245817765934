.root{
    transition: linear 0.3s all;
    width: 100%;
    position: fixed;
    top: 0;
    &:not(.active) {
        visibility: hidden;
        opacity: 0;
        z-index: -1;
    }
}
.btn {
    cursor: initial !important;
}


@media (min-width: 991px) {
    .root{
        width: 26.25rem;
        height: 28.5625rem;
        position: absolute;
        top: 100%;
        border-radius: 0.5rem;
    }
}

@media (max-width: 990px) {
    .root {
        height: 100vh !important;
    }
}