.root {
    width: 50%;
    min-width: 51rem;
    position: fixed;
    transition: linear 0.3s all;
}

.btnWrapper {
    padding-right: 1.25em;
    >button {
        top: 1.5rem;
    }
}

.main {
    > * {
        padding: 0 1.5rem;
        @media (max-width: 1200px) {
            padding: 0 1rem;
        }
    }
}

.cards {
    scroll-behavior: smooth;
}


@media (max-width: 992px) {
    .root {
        width: 30rem;
        min-width: initial;
        max-width: 100%;
        position: fixed;
        >button {
            right: 0;
            margin: 0 1rem;
        }
    }
}