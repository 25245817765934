.news-sentiment {
  &-positive {
    color: #4caf50;
  }
  &-negative {
    color: #f44336;
  }
}

.sentiment-card {
  background: #f8f9fa;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding: 8px;
  margin-bottom: 10px;
  
  .sentiment-title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 4px;
  }
  
  .sentiment-value {
    text-align: center;
    font-size: 1.1em;
    font-weight: bold;
  }
} 