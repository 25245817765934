.Header-Links {
    @extend .list-unstyled, .text-nowrap, .flex-1, .flex-lg-initial, .d-flex;
    li:not(.Header-Menu-Button) {
        flex: 1;
    }
}

.Header-Menu-Button {
    width: 5rem;
    border-left: 1px solid rgba($color: #fff, $alpha: 0.2);
}

.active .Header-Menu-Button svg {
    transform: rotate(45deg) scale(1.1);
}

.Header-Links:not(.active) .Header-Menu-Button svg {
    width: 1.5em;
}

@media (max-width: 992px) and (min-width: 767px) {
    .Header-Links {
        max-width: 30rem;
        margin-left: auto;
    }
}


@media (max-width: 992px) {
    .Header-Links:not(.active) + div {
        display: none;
    }
}