.text-field {
    border: 1px solid var(--accent-6);
    outline: none;
    font-size: inherit;
    padding: 0.59rem 1rem;
    border-radius: 4rem;
    &:not(:disabled) {
        &:focus, &:hover{
            border-color: var(--primary);
        }
    }
    &:disabled {
        opacity: 0.7;
    }
}