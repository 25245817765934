.animation-scale:not(.no-animation) {
    animation: linear 0.2s AnimationScale forwards;
    transform: scale(0);
    opacity: 0;
}

.animation-scale-down {
    animation: linear 0.2s AnimationScaleDown forwards;
    transform: scale(1);
    opacity: 1;
}

.animation-opacity {
    animation: linear 0.3s AnimationOpacity forwards;
    opacity: 0;
}

.animation-opacity-down {
    animation: linear 0.2s AnimationOpacityDown forwards;
    opacity: 1;
}

.delay-3 {
    animation-delay: 0.3s;
}

.delay-6 {
    animation-delay: 0.6s;
}

.animate-bounce {
    animation: bounce 1s infinite;
}

@keyframes bounce {

    0%,
    100% {
        margin-top: -1rem;
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }

    50% {
        margin-top: 0;
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}


@keyframes AnimationOpacityDown {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes AnimationOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes AnimationScale {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes AnimationScaleDown {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes SpinnerAnimation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}



.animation-from-down {
    animation: linear 0.3s forwards AnimationFromDown;
}

@keyframes AnimationFromDown {
    0% {
        top: 100%;
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}