.root{
    z-index: 10004;
    background: #fff;
    width: calc(100% - 10rem);
    bottom: 0;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-radius: 0.1em;
    font-size: 13px;
    font-weight: normal;
}

[class *= "hasTouchEffect"] {
    a:hover{
        text-decoration: underline;
    }
}
@media (max-width: 992px) {
    .root{
        text-align: center;
        padding-top: 1rem;
        margin: 1rem !important;
        width: calc(100% - 2rem);
    }
}