.leading {
    min-width: 2.125rem;
    height: 2.125rem;
    max-width: 2.125rem;
    img{
        border-radius: inherit;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.root:hover{
    background: var(--surface-2);
}