.__blur{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba($color: #0000004D, $alpha: 0.3);
}
.animate{
    animation: 0.5s Animate forwards;
}
.popup-footer{
    height: 3.333rem;
    margin: 2.667rem -2.667rem -2.667rem -2.667rem !important;
    border-radius: 0 0 0.2778rem 0.2778rem !important;
    overflow: hidden;
    display: flex;
    button, .custom-btn:not(.f1){
        border-radius: 0 !important;
        min-height: 3.33rem !important;
        width: 50% !important;
        min-width: initial !important;
        flex: 1;
    }
}
.ml-popup{
    margin-left: 2.667rem;
}
.mr-popup{
    margin-right: 2.667rem;
}
.mt-popup{
    margin-top: 2.667rem;
}
.mb-popup{
    margin-bottom: 2.667rem;
}
.font-pop-header {
    font-size: 15px;
}
@media (max-width: 550px){
    .ml-popup{
        margin-left: 1.714rem;
    }
    .mr-popup{
        margin-right: 1.714rem;
    }
    .popup-footer{
        margin: 1.714rem -1.714rem -1.714rem -1.714rem !important;
    }
}
@keyframes Animate{
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}