.Modal-Close {
    position: fixed;
    height: 100%;
    right: 1.25rem;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.Modal-Wrapper {
    position: fixed;
    z-index: 10001;
    display: flex;
    padding: 1rem;
    overflow: auto;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(var(--text-rgb), 0.5);
}

.Modal-Main {
    background: var(--surface-1);
    margin: auto;
    min-height: 20.6875rem;
    min-width: 34.5rem;
    border-radius: 0.875rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @extend .gap-3, .gap-lg-4, .z-index-1, .overflow-auto, .elevation-3;
}

.Modal-Main.Modal-Small {
    min-width: 22rem;
    min-height: initial;
    max-width: 22rem;
}

.Modal-Normal .Modal-Main {
    max-width: 34.5rem;
}

@media (min-width: 1024px) {
    .Modal-Large .Modal-Main {
        max-width: 72rem;
        width: 72rem;
    }
}

@media (min-width: 767px) {
    .Modal-Large .Modal-Main {
        max-width: 72rem;
        width: 72rem;
    }
    .Auth-Modal {
        min-width: 29rem !important;
        max-width: 29rem !important;
    }
}

@media (max-width: 992px) {
    .Modal-Main {
        min-width: 30rem;
        min-height: 17rem;
    }
}

@media (max-width: 767px) {
    .Modal-Small {
        border-radius: 0.5rem;
        min-width: initial;
    }

    .Modal-Main {
        max-height: var(--app-height);

        &:not(.Modal-Small) {
            min-height: 14.5rem;
            min-width: initial;
            width: 100%;
            height: 100%;
            border-radius: 0;
            max-width: initial;
        }
    }

    .Modal-Wrapper {
        padding: 0;
    }

    .Modal-Main.Modal-Small {
        max-width: 19rem;
        min-width: 19rem;
        min-height: 11rem;
    }
}