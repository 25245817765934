.unRead {
    position: relative;
    &::before{
        content: '.';
        color: var(--accent-2);
        position: absolute;
        top: -1em;
        right: -0.1em;
        font-size: 3em;
    }
}