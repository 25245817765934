.root {
    min-width: 20rem;
    width: 20rem;
    border-left: solid 0.0625rem var(--accent-6);
    @media (max-width: 1300px) {
        min-width: 18rem;
        width: 18rem;    
    }
    @media (max-width: 991px) {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background-color: inherit;
    }
}

.main {
    >div {
        padding: 1rem;
        display: grid;
        gap: 0.25rem;
        .action {
            text-transform: uppercase;
            font-weight: 700;
        }
        >p[title] {
            margin: 0;
            display: flex;
            font-size: .875rem;
            &::before {
                content: attr(title);
                min-width: 5em;
                font-weight: 600;
            }
        }
        &[class *= "Buy"] {
            background-color: rgb(10, 193, 113, 0.1);
            .action {
                color: var(--accent-3);
            }
        }
        border-top: .0625rem solid rgba($color: #000000, $alpha: 0.1);
        &[class *= "Sell"] {
            background-color: rgb(220, 0, 0, 0.1);
            .action {
                color: var(--accent-2);
            }
        }
    }
}