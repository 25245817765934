.root{
    height: 27.56rem;
    &[class *= "active"] .triangle{
        opacity: 1;
    }
    
}
.main{
    width: 28.25rem;
}
.triangle{
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid var(--surface-1);
    width: 16px;
    opacity: 0;
}
.dots{
    >li {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        background: currentColor;
        opacity: 0.5;
        transition: 0.3s all linear;
    }
}

.layout1 {
    img {
        min-height: 17.5rem;
    }
}

.layout1 .dots >li:first-child {
    opacity: 1;
}
.layout2 .dots >li:nth-child(2) {
    opacity: 1;
}
.layout2 .triangle {
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-right: 8px solid #fff;
    border-left: none;
    margin: 4rem 0 auto 0 !important;
}
.layout3 .dots >li:last-child {
    opacity: 1;
}

.img2{
    height: 13.18rem;
}