
.priceDetails{
    &::after{
        content: '';
        position: absolute;
        height: 0.0625rem;
        left: 0;
        right: 0;
        background: currentColor;
        top: 50%;
        transform: translateY(-50%);
    }
}
.freeTrialBadge{
    height: 1rem;
    display: flex;
    margin-top: 0.25rem;
    >span{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 1rem;
        font-size: 0.625rem;
    }
}