@import '../../scss/colors.scss';
.root {
  form {
    max-width: 70rem;
  }
}

.google-login {
  color: red !important;
}

.btn {
  font-size: 1rem !important;
  padding: 0.845em !important;
  background: $grey-lighten-2;
  line-height: 1em !important;
  cursor: pointer;
  font-weight: normal !important;
  border: none !important;
  border-radius: 4px;
  &:not(.small-btn) {
    min-height: 42px;
  }
  &:hover {
    background: rgba($color: $grey, $alpha: 0.7) ;
  }
  &:not(.f1),
  &:not(.small-btn) {
    min-width: 11.5rem !important;
  }
  &:not(.text-uppercase) {
    text-transform: initial !important;
  }
  &.small-btn {
    min-width: initial !important;
    padding: 0.85em 1em !important;
    border-radius: 0.4em;
  }
}
.btnPrimary{
  @extend .btn;
  color: #fff ;
    background: $primary ;
    &:hover{
        background: rgba($color: $primary, $alpha: 0.7) !important;
    }
}


.btnRounded{
  font-size: 1rem;
  padding: 0.63em 3.23em;
  border: none;
  outline: none;
  white-space: nowrap;
  border-radius: 4em;
  font-family: Inter;
  cursor: pointer;
  position: relative;
  &:not(:active):hover{
    box-shadow: 0px 0.6em 1.4em rgba(0, 0, 0, 0.15);
  }
}

.btnRoundedBlack{
  @extend .btnRounded;
  background: #333333;
  color: #fff;
}
.btnRoundedBlue{
  @extend .btnRounded;
  background: #5D5FEF;
  color: #fff;
}
.btnRoundedWhite{
  @extend .btnRounded;
  color: #5D5FEF;
  background: #fff;
}