.root {
    button {
        transform: scale(0.8);

        svg {
            max-width: 1em;
        }
    }

    [class *="MuiInputBase-root"] {
        padding-right: 0 !important;
    }
}

.dialog {
    z-index: 10002 !important;
}