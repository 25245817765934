.root {
    position: relative;
    display: block;
    img {
        object-fit: cover;
    }
    >*:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &::after, >*:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &::after {
        content: "";
    }
}