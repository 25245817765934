.chatbtn {
    border-radius: 2rem 0 0 2rem;
    z-index: 5;
    position: fixed;
    right: 0;
    bottom: 3.2rem;
    height: 4rem;
    transition: linear 0.2s all;
    transform-origin: bottom right;
    &:hover {
        background: linear-gradient(135deg, #BE5BF3 100%, #9020CC 0%), radial-gradient(174.61% 159.15% at 100.00% 0.00%, #7203FF 100%, #F800DF 0%) !important;
    }
    svg {
        width: 2rem;
    }
    &.opening {
        transform: scale(0);
        opacity: 0;
    }
}