$height: var(--app-height);

$header: 4rem;
$header-sm: $header - 0.5rem;
$footer: 2.8rem;
$footer-sm: $footer + 3.45rem;
$vh-height: calc($height - ($header + $footer));
$vh-height-sm: calc($height - ($header-sm + $footer));

$vh-height-header: calc($height - $header);
$vh-height-header-sm: calc($height - $header);

$footer-xs: $footer-sm + 0.7rem;

.header-height {
    min-height: $header;
}

.Footer-Padding {
    padding-top: $footer;
}

.mt-header {
    margin-top: $header;
}

.top-header {
    top: $header;
}

.vh-page {
    height: $vh-height;
}

.min-vh-page {
    min-height: $vh-height;
}

.vh-page-header {
    height: $vh-height-header;
}
.min-vh-page-header {
    min-height: $vh-height-header;
}

.max-vh-page-header {
    max-height: $vh-height-header;
}

@media (max-width: 991px) {
    .Footer-Padding {
        padding-top: $footer-sm;
    }

    .header-height {
        min-height: $header-sm;
    }

    // .top-header {
    //     top: $header-sm;
    // }


    .vh-page {
        height: $vh-height-sm;
    }

    .min-vh-page {
        min-height: $vh-height-sm;
    }

    .vh-page-header {
        height: $vh-height-header-sm;
    }
    .min-vh-page-header {
        min-height: $vh-height-header-sm;
    }
    .max-vh-page-header {
        max-height: $vh-height-header-sm;
    }
}

@media (max-width: 576px) {
    .Footer-Padding {
        padding-top: $footer-xs;
    }
}