.root {
    >div:first-child {
        z-index: 1;
        color: var(--surface-1);
        margin: -0.25em;
    }

    @media (min-width: 575px) {
        min-width: 23rem;
        width: 23rem;
    }
}

.title {
    margin: -6rem -2rem 0 -2rem;

    >div {
        padding: 60% 2rem 2rem 2rem;
        backdrop-filter: blur(0.2rem);
        background-color: rgba(var(--text-rgb), 0.2);
    }

    @media (max-width: 991px) {
        margin: -4.5rem -1.5rem 0 -1.5rem;

        >div {
            padding: 60% 1.5rem 1.5rem 1.5rem;
        }
    }

    @media (max-width: 575px) {
        margin: -4.5rem -1rem 0 -1rem;

        >div {
            padding: 60% 1rem 1rem 1rem;
        }
    }
}