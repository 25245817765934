.root {
    max-width: 500px;
}
.main {
    padding-top: 60%;
    img {
        position: absolute;
        object-fit: cover;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: linear 0.5s all;
        border-radius: 0.5rem;
        &:not(.active) {
            opacity: 0;
            visibility: hidden;
        }
    }
}

.bar {
    display: flex;
    align-items: center;
    &::after, &::before {
        content: '';
        position: absolute;
        height: 0.25em;
        left: 0.75em;
    }
    &::after {
        background-color: var(--primary);
        right: calc(100% - 0.75em);
    }
    &::before {
        background-color: var(--surface-3);
        right: 0.75em;
        opacity: 0.2;
    }
    &[class *= "playing"] {
        transition: linear all;
        &::after {
            right: 0.75em;
            transition: inherit;
        }
    }
}