.item{
    height: 2.625rem;
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 0 1.125rem;
    max-width: 15.3125rem;
    &:nth-child(5) ~ .item{
        display: none !important;
    }
}
.root{
    gap: 0.75rem;
    left: 10vw;
    right: 10vw;
}
.title{
    font-size: 0.9375rem;
}
@media (max-width: 1050px){
    .root{
        left: 5vw;
        right: 5vw;
    }
    .item:nth-child(4) ~ .item{
        display: none !important;
    }
}
@media (max-width: 992px) {
    .root{
        left: 2rem;
        right: 2rem;
    }
    .item:nth-child(3) ~ .item{
        display: none !important;
    }
}
@media (max-width: 576px) {
    .root {
        left: 1rem;
        right: 1rem;
    }
    .item:nth-child(2) ~ .item{
        display: none !important;
    }
}