.root {
    font-size: .875rem;
    display: grid;
    height: 2.857em;
    align-items: center;
    border-top: .0625rem solid var(--accent-6);
    color: var(--accent-4);
    grid-template-columns: 4em 1fr auto;
    &:last-child {
        border-bottom: 0.0625rem solid var(--accent-6);
    }
    &::before {
        content: attr(ticker);
        min-width: 4em;
        order: -1;
        color: var(--text);
    }
    &::after {
        content: attr(company);
        flex: 1;
        order: -1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 1em;
    }
    &[class *= "cursor-pointer"]:hover {
        border-color: var(--primary);
        color: var(--primary);
        &::before {
            color: inherit;
        }
        & + .root {
            border-top-color: var(--primary);
        }
    }
}