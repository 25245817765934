.root {
    width: 1.5em;
    height: 1.5em;
    font-size: 1rem;
    border-radius: 50%;
    background: rgba(53, 126, 223, 0.2);
    filter: drop-shadow(0 0 0.3rem var(--primary));
    &::after, &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        opacity: 0.7;
    }
    &::before {
        border: 0.07em solid;
    }
    &::after {
        background: currentColor;
        transform: scale(0.5);
    }
    &.right {
        left: 100%;
    }
}