.root {
    padding: 1rem;
    &:nth-child(odd) {
        background-color: var(--surface-3);

    }
}
.main {
    grid-template-columns: auto 1fr;
    gap: 0.5rem 1rem;
    b {
        width: 8em;
    }
    @media (max-width: 900px) {
        b {
            width: 7em;
        }
    }
    @media (max-width: 500px) {
        gap: 0.5rem;
        b {
            width: initial;
        }
    }
}