$border: 0.125em;

.InputFieldV2-Wrapper {
    padding: 0;
    position: relative;
    line-height: 0;
    border: $border solid var(--accent-6);
    transition: linear 0.1s all;
    border-radius: 0.5em;
    overflow: hidden;

    input {
        border: none;
        outline: none;
        background: transparent;
        line-height: 0;
        font-size: inherit;
        padding: 1em (1em + $border);
        flex: 1;
        position: relative;
        z-index: 1;
        border-radius: inherit;
    }

    legend {
        color: var(--accent-4);
        position: absolute;
        top: 1.5em + $border;
        margin-left: 1em;
        transition: inherit;
        left: 0;
    }

    &:has(:disabled) {
        opacity: 0.8;
    }

    label {
        display: flex;
        align-items: center;
        gap: 1em;
    }
}

.InputFieldV2-Wrapper:not(:has(input:disabled)) {
    &:hover {
        border-color: var(--accent-5);
    }

    &:has(:focus) {
        border-color: var(--primary);

        legend {
            color: var(--primary);
        }
    }
}

.InputFieldV2-Wrapper {

    &:has(:focus),
    &:has(.InputFieldV2-Value),
    &:has(.InputFieldV2-Start),
    &:has(:autofill),
    &:has(:-webkit-autofill) {
        legend {
            position: relative;
            top: 0;
            transform: scale(0.8);
            text-align: left;
            z-index: 2;
            margin-left: 0.64em;
        }
    }
}


.InputFieldV2-Wrapper:has(.InputFieldV2-blur.InputFieldV2-Invalid),
.InputFieldV2-Wrapper:has(.InputFieldV2-blur:invalid),
.InputFieldV2-Wrapper:has(.InputFieldV2-Invalid) {
    border-color: rgba(255, 0, 0, 0.6) !important;

    legend {
        color: var(--accent-2) !important;
    }

    &~small {
        color: var(--accent-2);
        display: block !important;
    }
}

span.InputFieldV2-left {
    padding-left: $border + 1em;
    margin-right: -$border;
    order: -1;
}

span.InputFieldV2-right {
    padding-right: 1em;
}