.ToolPage {
    border: 1px solid #D1D1D1;
    background-color: #fff;
    border-radius: .5rem;
    margin: 1rem;
    font-size: .875rem;
    h1 {
        font-size: 1rem;
        margin: 0;
        background-color: white;
        position: relative;
        z-index: 2;
        text-transform: capitalize;
    }
}

.ToolPage-Main {
    padding: 1rem;
    flex: 1;
}


@media (min-width: 991px) {
    .ToolPage {
        display: flex;
        max-height: calc(var(--app-height) - 9.5rem) !important;
        overflow: hidden;
        padding-right: 0;
        h1 {
            font-size: 1.125rem;
        }
    }
    .ToolPage-Main {
        &:nth-child(2) {
            padding-left: 0;
        }
    }
}

@media (max-width: 1200px) {
    .ToolPage {
        margin: 0.5rem 0;
        border-radius: 0;
    }
    
}

input.ToolPage-Responsive-Check:not(:checked) + .blur {
    // display: none;
    visibility: hidden;
    opacity: 0;
}

.ToolPage-Responsive-Check {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}