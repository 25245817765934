@import './page-spacing.scss', "./animation.scss", "./bootstrap.scss", "./flex.scss", "./filter-panel.scss",
"./grid.scss", "./elevations.scss", "./colors.scss", "./buttons.scss", "./fontsize.scss",
"./borderRadius.scss", "./table.scss", './loader.scss', './mui.scss', './scrollbar.scss',
'./card.scss', './form.scss', './header-footer.scss', './header.scss', './algoitem.scss', 
'./Tool.scss', './modal.scss';

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body,
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  margin: 0;
  font-size: 20px;
  color: var(--text);
  background: var(--surface-3);
  scroll-behavior: smooth;
}

.img-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0;

  >img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.UserAvatar {
  @extend .img-wrapper, .bg-accent-5;
  width: 2.625em;
  border-radius: 50%;
  height: 2.625em;
  min-width: 2.625em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

body {
  min-height: var(--app-height);
}

body.development>iframe {
  display: none !important;
}

.letter-spacing {
  letter-spacing: 0.2em;
}

.premium {
  position: relative;
  overflow: initial !important;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid var(--accent-7);
    border-radius: inherit;
    transform: scale(1.1);
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.modal-pagination {

  a,
  button {
    font-size: inherit;
  }
}

.borer-none {
  border: none;
}

.border {
  border: solid 0.0625rem var(--accent-6);
}

.border-left {
  border-left: solid 0.0625rem var(--accent-6);
}

.border-right {
  border-right: solid 0.0625rem var(--accent-6);
}

.border-bottom {
  border-bottom: solid 0.0625rem var(--accent-6);
}

.border-top {
  border-top: solid 0.0625rem var(--accent-6);
}

.border-gray {
  border-color: #D1D1D1;
}

.border-y {
  @extend .border-bottom, .border-top;
}

.rotate--90 {
  transform: rotate(-90deg);
}

.-scale-1 {
  transform: scaleX(-1);
}

.rotate-45 {
  transform: rotate(45deg);
}

.rotate-60 {
  transform: rotate(60deg);
}

.rotate-90 {
  transform: rotate(90deg);
}

.rotate-120 {
  transform: rotate(120deg);
}

.rotate-135 {
  transform: rotate(135deg);
}

.rotate-180 {
  transform: rotate(180deg);
}

.rotate-240 {
  transform: rotate(240deg);
}

.rotate-225 {
  transform: rotate(225deg);
}

.rotate-270 {
  transform: rotate(270deg);
}

.rotate-305 {
  transform: rotate(305deg);
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.no-opacity {
  opacity: 1 !important;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.no-line-margin {
  margin: -0.2em 0;
}

.container,
.container-v2 {
  margin-left: auto;
  margin-right: auto;
}

.max-w-100 {
  max-width: 100%;
}

.container {
  width: 80vw;
}

.container-v2 {
  width: 60vw;
}

svg {
  min-width: 0.7em;
}

.background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-squar {
  border: 0.125em solid;
  width: 0.625em;
  height: 0.7em;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media (max-width: 2000px) {

  html,
  body {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .container-v2 {
    width: 80vw;
  }

  html,
  body {
    font-size: 14px;
  }
}

@media (max-width: 1050px) {

  .container,
  .container-v2 {
    width: 90vw;
  }
}

@media (max-width: 992px) {

  .container,
  .container-v2 {
    width: initial;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  html,
  body {
    font-size: 16px;
  }
}

@media (max-width: 576px) {

  .container,
  .container-v2 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (max-width: 360px) {

  html,
  body {
    font-size: 14px;
  }
}

@media (max-width: 340px) {

  html,
  body {
    font-size: 13px;
  }
}

.absolute-full {
  @extend .position-absolute, .start-0, .w-100, .top-0, .h-100;
}

.notifications-wrapper:not(:empty) {
  width: 24rem;
  @extend .position-fixed, .start-0, .vh-100, .bg-primary, .top-0, .z-index-8;

  >div {
    @extend .position-relative, .d-flex, .align-items-center, .gap-3, .bottom-0, .z-index-8, .elevation-1, .bg-surface-1, .p-3, .m-3, .start-0, .border-radius-8, .animation-from-down;

    img {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.rounded-badge {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  border-radius: 50%;
  color: var(--surface-1);
}


.flex-container {
  >div {
    height: 100%;
  }
}

.tabs-leaderboard {
  @extend .d-sm-grid, .d-lg-none, .text-center, .d-flex, .bg-surface-1, .justify-content-center, .text-accent-4;
  padding: .375rem 0;
  grid-template-columns: repeat(3, 7.8125rem);

  a,
  button {
    padding: 0.38rem 0;
    flex: 1;
    white-space: nowrap;
    border: none;
    background-color: transparent;

    &:not(:last-child) {
      border-right: 1px solid var(--accent-6);
    }

    &[class *="active"] {
      color: var(--text);
      font-weight: 600;
    }
  }
}

.blur {
  background-color: rgba(var(--text-rgb), 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}