.root {
    .main {
        max-height: 1000px;
        transition: linear 0.3s all;
        margin-top: 1rem;
    }
    &:not(.active) .main {
        overflow: hidden;
        max-height: 0;
        opacity: 0;
        margin-top: 0;
    }
    button {
        color: inherit;
        >svg {
            transition: linear 0.3s transform;
        }
    }
    // &.active button {
    //     color: var(--primary);
    // }
}


body[class *= "hasHoverEffect"] .root {
    button:hover {
        color: var(--primary);
    }
}