.root {
    svg {
        left: 0;
    }
    input {
        border: none !important;
        padding-left: 1.5rem;
        min-height: 2.375rem;
    }
}

.rounded {
    input {
        padding-right: 2.25rem;
    }
}