$left: 21rem;

.ToolPage-LeftSection {
    padding: 1rem;
    width: $left;
    background-color: white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    >div {
        padding-right: 1rem;
        margin-right: -1rem;
    }
}

@media (max-width: 1300px) {
    .ToolPage-LeftSection {
        width: 18rem;
    }
}

@media (max-width: 991px) {
    .ToolPage-LeftSection {
        width: initial;
    }

    input.ToolPage-Responsive-Check+.ToolPage,
    input.ToolPage-Responsive-Check+.blur+.ToolPage {
        .ToolPage-LeftSection {
            position: fixed;
            left: 0;
            top: 0;
            z-index: 10;
            height: var(--app-height);
            max-height: initial;
            left: 0;
            transition: linear 0.2s all;
            width: $left;

        }
        .ToolPage-CloseButton {
            position: fixed;
            top: 1rem;
            left: $left + 1rem;
            background: white;
            z-index: 8;
            display: flex !important;
        }
    }

    input.ToolPage-Responsive-Check:not(:checked)+.ToolPage {
        .ToolPage-LeftSection {
            left: -$left;
            opacity: 0;
            visibility: hidden;
        }

        .ToolPage-CloseButton {
            opacity: 0;
            visibility: hidden;
        }
    }
}


@media (max-width: 400px) {
    input.ToolPage-Responsive-Check+.ToolPage,
    input.ToolPage-Responsive-Check+.blur+.ToolPage {
        .ToolPage-LeftSection {
            transform: scaleX(0.95);
            transform-origin: left top;
            >div:first-child {
                transform: scaleY(0.95);
                transform-origin: inherit;
            }
        }
        .ToolPage-CloseButton {
            left: $left - 0.2rem;
        }
    }
}