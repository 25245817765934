.root {
    font-size: 1rem;
    border-radius: 0.5em;
    &, * {
        transition: linear 0.2s all;
    }
}

.main {
    padding: 1em;
    gap: 0.5em;
    margin-top: -1.25em;
    h5 {
        font-size: 1.25em;
        margin: 0;
    }
}


.btn {
    top: 0.25em;
    left: 0.25em;
}

.badge {
    top: -2.25em;
    right: 0.5em;
    border-radius: 2em;
    padding: 0.25em 0.5em;
}

.root:not(:hover) {
    .main {
        margin-top: 0;
    }
    h5 svg {
        opacity: 0;
        visibility: hidden;
    }
    .blog {
        -webkit-line-clamp: 3 !important;
        height: 3.75em !important;
    }
}

.blog {
    height: 5em;
}