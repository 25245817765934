.root {
    position: fixed;
    z-index: 10;
    bottom: 0;
    right: 0;
    transition: linear 0.2s all;
    transform-origin: right bottom;
    width: 100%;
    height: 100%;
    &:not([class *="active"]) {
        opacity: 0;
        transform: scale(0);
    }
}

.chat {
    >p {
        margin: 0;
        max-width: 80%;
        background-color: var(--accent-6);
        padding: .5rem .75rem;
        border-radius: 0.5rem 0.5rem 0.5rem 0;
        margin-right: auto;
        &.user {
            background: linear-gradient(135deg, #BE5BF3 0%, #9020CC 100%);
            color: var(--surface-1);
            margin: 0 0 0 auto;
            border-radius: 0.5rem 0.5rem 0 0.5rem;
        }
    }
}

.error {
    background: rgba(220, 0, 0, 0.20);
    padding: .75rem;
    >div {
        gap: .625rem;
        margin-bottom: .625rem;
    }
}

@media (min-width: 570px) {
    .root {
        width: 27.75rem;
        border-radius: 1rem;
        bottom: 4rem;
        right: 1rem;
        @media (min-height: 700px) {
            height: 40.8125rem;
        }
    }
}

.suggestion {
    button {
        white-space: initial;
        text-align: right;
        border: 1px solid var(--accent-5);
        color:  var(--accent-4);
        font-size: .875rem;
        padding: 0.5rem 1rem;
        border-radius: 2rem;
        &:hover:not(:active) {
            color: var(--primary);
            border-color: var(--primary-lighten-2);
        }
    }
}

.header {
    background: linear-gradient(135deg, #BE5BF3 0%, #9020CC 100%);

    svg {
        width: 1.5rem;
    }
}

// Add pulse animation when tokens exceed context length
.pulseAnimation {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}