.TipCard-Root {
    width: 20.6rem;
    background: hsla(219, 29%, 12%, 0.9);
    white-space: normal;
    text-transform: none;
    left: 100%;
    top: 0;
    transition: linear 0.2s opacity;
    left: 0;
    opacity: 0;
    visibility: hidden;
    margin-left: 4rem;
    &.active {
        animation: forwards 0.3s linear TipCard-Root;
        visibility: visible;
    }
    &:first-child {
        animation-delay: 1s;
    }
    &::before {
        content: '';
        display: block;
        height: 0.625rem;
        margin-bottom: 1.375rem;
        background: var(--primary);
    }
}

.TipCard-Desc{
    line-height: 1.71em;   
}


@keyframes TipCard-Root {
    0%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}