
.ToolPage-Header ~ .ToolPage {
    .ToolPage-Item {
        display: none !important;
    }
    .withTool-Item {
        opacity: 1;
        position: static;
        z-index: initial;
    }
}

.withTool-Item {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

@media (min-width: 991px) {
    .ToolPage-Header{
        min-height: 3.5rem;
    }
    .ToolPage-Header ~ .ToolPage {
        height: calc(var(--app-height));
        .ToolPage-LeftSection {
            padding-top: 1rem;
            margin-top: 0;
            height: 100%;
        }
    }
}

@media (max-width: 767px) {
    .ToolPage-btn-wrapper{
        order: 1;
        width: 100%;
    }
}