@import './buttons-hover.scss';

.loader {
  content: "";
  display: inline-block;
  width: 0.9em;
  height: 0.9em;
  border-radius: 1em;
  border: 0.15em solid transparent;
  border-left-color: currentColor;
  border-top-color: currentColor;
  line-height: 1em;
  animation: linear 0.5s infinite SpinnerAnimation;
}


.svg-normal {
  width: 1.25em;
}

.btn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  font-size: 1rem;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625em;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.2s all;
  white-space: nowrap;

  svg {
    max-height: 1.25em;
    min-width: 1.25em;
  }

  &:not(:disabled):not(input) {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}

form.loading .btn-form,
.progress-btn {
  cursor: not-allowed;
  opacity: 0.3;

  &::after {
    @extend .loader;
  }

  svg {
    display: none;
  }
}

.btn-underline {
  text-decoration: underline;
}

.btn-text {
  color: var(--primary);

  &:not(:disabled) {
    &.active {
      text-decoration: underline;
    }

    &:active {
      color: var(--primary-darken-2);
    }
  }

  &:disabled {
    color: var(--text);
    cursor: not-allowed !important;
  }
}

.btn-primary,
.btn-border,
.btn-grediant {
  font-weight: 500;
  border-radius: 2em;
  font-size: 1.125rem;
}

.btn-primary,
.btn-border {
  height: 2.556em;
  padding: 0 2.222em;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--surface-1);
  position: relative;
  @extend %btn-hover;
}

.btn-border {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    border: 1px solid;
  }

  &:not(:disabled) {
    color: var(--primary);
  }
}

.badge {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: var(--primary);
  border: 1px solid;
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)),
    currentColor;
}

.btn-grediant {
  box-shadow: 0px 11px 23px rgba(0, 0, 0, 0.15);
  background: linear-gradient(90deg,
      var(--primary) 0.02%,
      var(--accent-3) 100.04%);
  color: var(--surface-1);
  height: 3.222em;
  min-width: 12.832em;

  &:active {
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.33) 0%,
        rgba(255, 255, 255, 0) 100%),
      linear-gradient(90deg, var(--primary) 0.02%, var(--accent-3) 100.04%);
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
  }
}

.btn-landing-page {
  font-weight: 500;
}

.btn-landing-page,
.btn-landing-page-lg {
  color: var(--accent-4);

  &:active,
  &.active {
    text-decoration: underline;
    color: var(--accent-4);
  }
}

input {
  &:not(:checked)~.btn-grediant-normal:not(:hover) {
    background-image: initial !important;
    background-clip: initial !important;
    -webkit-text-fill-color: initial !important;
    font-weight: normal;
  }

  &:checked~.btn-grediant-normal::before {
    content: "";
  }
}

.btn-grediant-normal {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(90deg, #025ED7 0%, #0AC171 57.6%) !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  position: relative;
  padding: 0.2em 0;

  &::before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 0.15em;
    max-height: 0.15em;
    background-image: inherit;
  }

  &:active,
  &.active {
    &::before {
      content: "";
    }
  }
}

.btn-icon,
.MuiIconButton-root {
  font-size: 1rem;
  min-width: 2.25em;
  width: 2.25em;
  height: 2.25em;
  border-radius: 1.125em;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    background: currentColor;
    opacity: 0;
  }

  &:active, &.active {
    &::after {
      opacity: 0.3;
      content: '';
      @extend .absolute-full;
      background-color: currentColor;
    }
  }

  &.no-padding {
    margin: -0.4em;
  }
}

.btn-icon.elipse {
  &::before {
    content: '';
    width: 0.25em;
    height: 0.25em;
    position: absolute;
    background-color: var(--accent-2);
    top: 0.25em;
    right: 0.25em;
    border-radius: 2em;
  }
}

.btn-icon:active .btn {
  background-color: var(--accent-5);
}

.MuiIconButton-root {
  svg {
    max-width: 0.7em;
  }
}

.btn-landing-page-lg,
.btn-grediant-normal {
  @extend .subtitle;
  font-weight: 600;
}

.btn-rounded {
  border: 1px solid;
  height: 2em;
  font-size: 0.9375rem;
  padding: 0 0.4rem 0 0.35rem;
  gap: 0.375rem !important;
  border-radius: 2rem;
  color: var(--accent-4);

  svg {
    width: 1.125em;
    height: 1.125em;
  }

  &:active,
  &.active {
    color: var(--primary);
  }

  &:active {
    background: rgba(var(--text-rgb), 0.05);
  }
}

.btn-large {
  font-size: 0.875rem !important;
  min-width: 13.5625rem;
  min-height: 2.9375rem;
}

.btn-small {
  padding: 0.619rem 2.511rem;
  font-size: 1rem;
}

.btn-primary-2 {
  @extend .btn-primary;
}

.btn-border-2 {
  @extend .btn-border;
}

.btn-primary-2, .btn-border-2 {
  @extend .btn, .small, .fw-normal;
  padding: 1em 0.86em;
  line-height: 0;
  height: initial;
}

.btn-subscription {
  @extend .btn, %btn-hover;
  padding: 0 2.33em;
  height: 3.08em;
  gap: 0.1em;
  font-size: 1.5rem;
  color: var(--accent-2);
  background-color: var(--accent-1);
  font-weight: 700;
  border-radius: 3em;
  flex-direction: column;

  &::before {
    background-color: rgba($color: #000000, $alpha: 0.5) !important;
  }

  small {
    font-weight: 400;
    white-space: pre-wrap;
  }
}

@media (max-width: 576px) {
  .btn-grediant {
    width: 100%;
  }

  .btn-subscription {
    padding: 0 1.3em;
  }
}