.main {
    >div {
        height: 15rem;
        overflow: auto;
    }
}

@media (min-width: 650px) {
    .main {
        width: 36.5625rem;
    }
}