$width: 18rem;

.root {
    min-width: $width;
    width: $width;
    position: sticky;
    top: 4rem;
}

@media (min-width: 991px) {
    .dashboard_view {
        position: fixed;
        z-index: 8 !important;
        top: 0;
        height: calc(var(--app-height) - 4.3rem);
        right: 0;
        // opacity: ;
        transition: linear 0.3s all;
        transform-origin: right top;
        top: 4rem;
        &:not(.active) {
            transform: ScaleX(0);
            opacity: 0;
        }
    }
}

@media (max-width: 991px) {
    .root {
        position: absolute;
        right: 0;
        z-index: 50000 !important;
        width: 100%;
        top: 0;
        height: calc(var(--app-height) - 4.3rem);
    }
}