.root {
    min-width: 22.75rem;
    max-width: 22.75rem;
    height: calc(100dvh - 7.6rem);
    max-height: calc(100dvh - 7.6rem);
    position: sticky;
    top: 0;
    left: 0;
    overflow: hidden;

    [class *="position-fixed"] {
        width: 22.75rem;
        left: 0;
    }

    >div {
        overflow: auto;
    }
}

.sticky-bottom {
    border: 2px solid;
}

@media (max-width: 990px) {
    .root {
        min-width: initial;
        max-width: initial;
        height: initial;
        max-height: initial;
        position: initial;
        border-right: none;

        >div {
            overflow: initial;
        }
    }

    input[class *="tool-filter-checkbox"] {
        & + div .root {
            position: fixed;
            height: 100%;
            transition: linear 0.3s all;
            width: 25rem;
            max-width: 100%;

            >div {
                overflow: auto;
            }

        }
        &:not(:checked) + div .root {
            left: -100%;
            opacity: 0;
        }
    }

    .footer {   
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
}