@media (max-width: 992px) {
    .root {
        position: absolute;
        border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
        margin-top: -1px;
        padding-top: 2rem;
        top: 100%;
        height: var(--app-height);
        background: inherit;
        right: 0;
        left: 0;
        [class *= "Header-Link"] {
            flex-direction: row;
            justify-content: flex-start;
            padding: 0 1rem;
            gap: 1rem;
            border-bottom: 1px solid rgba($color: #fff, $alpha: 0.2);
            >svg {
                width: 2.625em;
                text-align: left !important;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}