.root {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 1rem 0.75rem;
    align-items: center;
    >span {
        text-align: right;
        display: grid;
    }
    div {
        padding: 0.7rem;
        background-color: var(--primary);
    }
}