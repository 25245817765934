@media (min-width: 991px) {
    .root {
        padding: 0.5rem 0;
        margin-right: 0.5rem;
        a, button {
            padding: 0.585rem 1.125rem;
            display: block;

            &[class *="active"], &:hover {
                background: var(--surface-3);
            }
        }
    }
}

@media (max-width: 992px) {
    .root {
        opacity: 1;
        visibility: visible;
        position: relative;
        padding-left: calc(2.625em + 2rem);

        a, button {
            padding: 1rem 0;
            border-bottom: 1px solid rgba($color: var(--surface-1-rgb), $alpha: 0.3);
            display: block;

            &[class *="active"] {
                background: rgba(var(--surface-1-rgb), 0.1);
                border-color: var(--primary);
            }
        }
    }
}