.root {
    transition: linear 0.3s visibility, linear 0.3s opacity;
    top: 0;
    left: 0;
    min-width: 12.5rem;

    &.normal {
        li {
            padding: 0.585em 1.25em !important;
            &::before {
                content: initial;
            }
        }
    }
    
    li {
        display: flex;
        align-items: center;
        padding: 0.585em 1.25em;
        cursor: pointer;
        position: relative;
        svg {
            max-height: 1.25em;
        }
        &:hover,
        &.active {
            background: var(--surface-3);
            font-weight: 500;
        }

        &.active {
            &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: '\f00c';
                color: var(--primary);
                position: absolute;
                left: 1.25em;
            }

            &[value=""] {
                padding-left: 3em;
            }
        }
    }

    &.filled li {
        padding-left: 3em;
    }

    &:not(.active),
    ~div {
        visibility: hidden;
    }

    &:active {
        transition-delay: 0.3s;
    }

    &.dark {
        background-color: #151B26 !important;

        li:hover, li.active {
            background-color: rgba($color: var(--surface-1-rgb), $alpha: 0.15);
        }
    }
}

@media (max-width: 991px) {
    .root {
        left: 50% !important;
        top: 50% !important;
        right: initial !important;
        transform: translate(-50%, -50%);
        position: fixed;
        max-width: calc(100% - 2rem);
        min-width: 13rem;
        max-height: calc(100% - 2rem);
        overflow: auto;
        &.active~div {
            visibility: visible;
            transition: linear 0.3s all !important;
        }

        &:not(.active)~div {
            transition-delay: 0.3s;
        }
    }
}