.root {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.main {
    width: 2.5em;
    height: 1.5em;
    padding: .1875em;
    display: inherit;
    background-color: var(--accent-5);
    &, &::before {
        border-radius: 1em;
        transition: linear 0.1s all;
    }
    &::before {
        content: "";
        width: 1.125em;
        height: 1.125em;
        background-color: var(--surface-1);
        position: relative;
        left: 0;
    }
}

input:checked + .main {
    background-color: var(--primary);
    &::before {
        left: 1em;
    }
}