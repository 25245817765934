.heading {
    cursor: pointer;
    &::after {
        font-family: "Font Awesome 5 Free"; font-weight: 900;
        content: '\f062';
        margin-left: 0.25rem;
        color: var(--primary);
        opacity: 0;
    }
    &:hover, &.active {
        &::after {
            opacity: 1;
        }
    }
    &.desc::after {
        content: '\f063';
    }
}