.root {
    scroll-behavior: smooth;
    i {
        color: var(--accent-5);
        min-width: 2.625em;
        min-height: 2.625em;
        display: flex;
        justify-content: center;
        &::before {
            font-size: 1.75em;
            padding-top: 0.1em;
        }
    }
    >div {
        display: flex;
        gap: 1rem;
        p {
            margin: 0;
            padding: 0.5rem 0;
            border-radius: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        >button {
            margin-top: -1rem;
        }
    }
}