.root {
    z-index: 2;
    >div {
        border-radius: 0.5rem;
        overflow: hidden !important;
    }
    @media (max-width: 991px) {
        left: 1rem !important;
        z-index: 10;
        width: calc(100% - 2rem);
    }
}