.item {
    padding: 1rem;
    border-radius: 0.5rem;
    &:not(:nth-child(2n)) {
        background-color: var(--accent-6);
    }
}

.negative, .positive {
    position: relative;
    z-index: 1;
    padding: 0.4rem 0.8rem;
    border-radius: 2rem;
    overflow: hidden;
    font-weight: 600;
    border: 1px solid;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: currentColor;
        opacity: 0.07;
        z-index: -1;
    }
    b {
        color: var(--text);
    }
}

.negative {
    color: var(--accent-2);
}
.positive {
    color: var(--accent-3);
}