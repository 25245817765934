@media (min-width: 991px) {
    .algo-tool-wrapper {
        overflow: hidden;
    }

    .algo-tool-wrapper-main {
        overflow: auto;
        grid-template-columns: auto 1fr;
    }
}

.product_tool_table {
    flex: 1;
}

input {
    &.tool-filter-checkbox {
        &:not(:checked)+div {
            .tool-filter-item {
                display: none !important;
            }
        }

        &:checked+div {
            .algo-tool-wrapper-main {
                overflow: hidden !important;
            }
        }
    }

    &:not(.tool-filter-checkbox)+div {
        .tool-filter-label,
        .tool-filter-item {
            display: none !important;
        }
    }

    @media (min-width: 991px) {
        &+div {
            .tool-filter-item {
                display: none !important;
            }
        }
    }
}