.StockAlgos-Header {
    @extend .d-flex, .header-height, .gap-sm-5, .z-index-8, .text-surface-1, .bg-primary-darken-4, .position-sticky, .top-0;
    >div:first-child {
        @extend .my-auto, .me-lg-5, .me-md-4, .me-3;
        >a {
            @extend .ps-4;
        }
        img, svg {
            height: 1.9rem;
            @media (max-width: 410px) {
                height: 1.25rem;
            }
        }
    }
}

.Nav-DropDown {
    @extend .list-unstyled, .text-nowrap;
}

@import './header-links.scss';