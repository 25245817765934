.Copy-Banner {
    // background-color: rgba(var(--text-rgb), 0.7);
    background-color: var(--text);
    position: fixed;
    right: 5rem;
    border-radius: 3rem;
    padding: 1rem 1.5rem;
    gap: 1rem;
    color: var(--surface-1);
    z-index: 20;
    bottom: 5rem;
    transition: linear 0.2s all;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.15);
    @media (max-width: 800px) {
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0.5rem 0.5rem 0 0             ;
    }
}

input:not(:checked) + .Copy-Banner {
    opacity: 0;
    bottom: -1rem;
}