.xAxis{
    height: 1.25em;
    margin-left: -1em !important;
    text-align: center;
}
.chartWrapper{
    padding-top: 40%;
    min-height: 200px;
}

@media (min-width: 900px) {
    .chartWrapper{
        min-width: 700px;
    }
    .xAxis{
        margin-right: 0.5em;
    }
}