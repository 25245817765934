.__App-Footer {
    position: fixed;
}

.Make-Footer-Absolute ~ .__App-Footer {
    position: absolute;
    bottom: 0;
}

.Footer-Links {
    li:not(:last-child) {
        display: flex;
        align-items: center;
        gap: inherit;
        &::after {
            content: "|";
        }
    }
}

@media (max-width: 991px) {
    .__App-Footer {
        position: absolute;
        bottom: 0;
    }
}