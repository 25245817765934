.elevation-1,
.elevation-1-hover:hover {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.1);
}
.elevation-2,
.elevation-2-hover:hover {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.8), 0px 0px 1px rgba(0, 0, 0, 0.5);
}
.elevation-3,
.elevation-3-hover:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25);
}
.elevation-4,
.elevation-4-hover:hover {
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8), 0px 0px 1px rgba(0, 0, 0, 0.8);
}
.elevation-5,
.elevation-5-hover:hover {
  box-shadow: 0px 17px 46px rgba(0, 0, 0, 0.25);
}

.elevation-6,
.elevation-6:hover{
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.old-elevation-1{
  box-shadow: rgba(155, 155, 155, 0.2) 2px 2px 8px 0px;
  border: 1px solid rgba(155, 155, 155, 0.2);
}