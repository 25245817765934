input:checked ~ span .fieldWrapper .field{
  color: var(--primary);
  svg{
    opacity: 1;
  }
}
.root {
  font-size: 1rem;
}
.errorText {
  transition: linear 0.3s all;
}
form[class *= "submitted"] input:invalid ~ .errorText{
  display: block !important;
}
input[type = "checkbox"]:checked ~ span .fieldWrapper .field svg{
  transform: scale(1);
  color: var(--surface-1);
}
input[type = "radio"]:checked ~ span .fieldWrapper .field svg{
  transform: scale(0.6);
  color: transparent !important;
}

input[type="radio"] ~ span .fieldWrapper .field{
  border-radius: 50%;
}
input:disabled ~ span{
  opacity: 0.7;
  cursor: not-allowed;
  .field{
    color: var(--accent-4) !important;
  }
}
.fieldWrapper{
  height: 2.25em;
  min-width: 2.25em;
  max-width: 2.25em;
  border-radius: 50%;
  position: relative;
  margin: -0.5em 0 -1em -0.5em;
}
.field{
  border-radius: 0.375em;
  position: relative;
  width: 1em;
  height: 1em;
  display: flex;
  overflow: hidden;
  color: var(--accent-4);
  align-items: center;
  justify-content: center;
  &::before{
    height: calc(100% - 0.25em);
    content: '';
    flex: 1;
    border: 0.125em solid;
    z-index: 2;
    position: relative;
    border-radius: inherit;
  }
  &:hover{
    color: var(--primary);
  } 
  svg{
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scale(0);
    padding: 0.2em;
    background: var(--primary);
    transition: linear 0.1s all;
    opacity: 0;
    border-radius: inherit;
  }
}