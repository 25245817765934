.root {
  display: inline-block;
  white-space: nowrap;
  font-size: 1rem;
  &:not(.readonly) {
    cursor: pointer;
    span:hover {
      color: var(--primary);
      border-color: currentColor;
    }
    input:checked {
      ~ span {
        color: var(--primary);
        border-color: currentColor;
        svg {
          display: block;
        }
      }
    }
  }
  span {
    display: flex;
    align-items: center;
    padding: 0.307em 0.72925em 0.307em 0.66675em;
    border-radius: 4em;
    border: 0.0625em solid;
    gap: 0.375em;
    svg{
      width: 0.875em;
      height: 0.875em;
      border-radius: 1em;
      padding: 0.1em;
      background: currentColor;
      path{
        color: var(--surface-1);
      }
    }
  }
}
.root:not(.showCross) .svg{
  display: none;
}