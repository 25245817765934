.__signals-popup{
    width: 400px;
    .__form-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        @media (max-width: 600px) {
            grid-template-columns: 1fr;
        }
    }
}