$left: 1em;

.with-field {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border: 2px solid var(--accent-5);
    border-radius: 0.5em;
    z-index: 1;
    padding: 0;
    line-height: 1.25;
    font-size: 1rem;

    &:hover {
        border-color: var(--accent-4);
    }

    textarea {
        resize: none;
    }

    legend {
        margin-left: $left;
        max-width: 0;
        height: 0;
        visibility: hidden;
        line-height: 0;
        padding: 0;
    }
    legend, label {
        transition: linear 0.1s all;
        text-wrap: nowrap;
    }

    label {
        position: absolute;
        top: 1em;
        transition: linear 0.1s all;
        transform-origin: left;
        left: $left;
        max-width: 0;
        color: var(--accent-4);
        line-height: 1;
        font-size: 1em;
        z-index: -1;
    }

    &:has(:disabled) {
        cursor: not-allowed;
    }
}

.Field {
    background: none;
    outline: none;
    padding-block: 0;
    padding-inline: 0;
    padding: 0 $left;
    border-radius: inherit;
    flex: 1;
    min-width: 0;
    border: none;
    background-color: transparent;
    line-height: 1.25;
    min-height: 3em;
    font-size: inherit;
}

.with-field {
    &:has(.Field:focus) {
        border-color: var(--primary);
    }

    .Field {
        &:focus, &:not(:placeholder-shown) {
            ~label {
                margin-top: -($left * 1.45);
                font-size: 0.8em;
                left: $left + 0.2em;
                z-index: 1;
            }

            ~legend {
                font-size: 0.8em;
                padding: 0 0.2em;
                max-width: 100%;
            }
        }
    }
    .Field:focus ~label {
        color: var(--primary);
    }

}

.with-field:has(.Input-Blur), 
form.submitted .with-field {

    &:has(:invalid),
    &:has(.invalid) {
        border-color: var(--accent-2);

        label {
            color: var(--accent-2)
        }

        & + .with-field-error {
            display: block;
        }
    }
}

.with-field-error {
    width: 100%;
    color: var(--accent-2);
    text-align: right;
    font-size: 0.75rem;
    display: none;
}